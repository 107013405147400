import { parseException } from "../errors";
export class ExternalPropertiesController {
    constructor(axios) {
        this.axios = axios;
    }
    async findAssociatedWithUser() {
        try {
            const response = await this.axios.get("/external-properties/associated");
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async importAssociatedWithUser() {
        try {
            const response = await this.axios.post("/external-properties/import-associated");
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async getAgencyStatistics(dto) {
        try {
            const response = await this.axios.post("/external-properties/agency-statistics", dto);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async find(filter) {
        try {
            const response = await this.axios.get("/external-properties", {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async count(filter) {
        try {
            const response = await this.axios.get("/external-properties/count", {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async detail(id) {
        try {
            const response = await this.axios.get(`/external-properties/${id}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async getStatuses() {
        try {
            const response = await this.axios.get("/statuses");
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async agencyUpdate(id, data) {
        try {
            const response = await this.axios.patch(`/external-properties/${id}/agency`, data);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async linkWithAgency(id) {
        try {
            const response = await this.axios.post(`/external-properties/${id}/agency`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async import(id) {
        try {
            const response = await this.axios.post(`/external-properties/${id}/import`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async updateBatch(batch) {
        try {
            const response = await this.axios.patch(`/external-properties/batch`, batch);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
