export class PropertiesController {
    constructor(axios) {
        this.axios = axios;
    }
    async retryChannelSync(dto) {
        try {
            const response = await this.axios.post("/properties/channel-sync-retry", dto);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async getStatistics(filter) {
        try {
            const response = await this.axios.post("/properties/statistics", filter);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async getChannelState(propertyId, channelCode) {
        try {
            const response = await this.axios.get(`/properties/${propertyId}/channels/${channelCode}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async getZonesInUse() {
        try {
            const response = await this.axios.get("/properties/zones-in-use");
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async existsReference(reference) {
        try {
            const response = await this.axios.get("/properties/exists-reference", {
                params: { reference },
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async find(filter) {
        try {
            const response = await this.axios.get("/properties", {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async findMap(filter) {
        try {
            const response = await this.axios.get("/properties/on-map", {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async detail(id) {
        try {
            const response = await this.axios.get(`/properties/${id}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async count(filter) {
        try {
            const response = await this.axios.get("/properties/count", {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async create(data) {
        try {
            const response = await this.axios.post("/properties", data);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async update(id, data) {
        try {
            const response = await this.axios.patch(`/properties/${id}`, data);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async publish(id) {
        try {
            const response = await this.axios.post(`/properties/${id}/publish`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async iaGenerateContent(dto, onProgress) {
        try {
            const response = await this.axios.post(`/properties/ia-generate-content`, dto, {
                responseType: "stream",
            });
            let partialText = "";
            const reader = response.data.getReader();
            const decoder = new TextDecoder();
            return await new Promise(async (resolve) => {
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) {
                        resolve(partialText);
                        break;
                    }
                    if (value) {
                        let chunk = decoder.decode(value);
                        partialText += chunk;
                        onProgress(chunk);
                    }
                }
            });
        }
        catch (e) {
            throw e;
        }
    }
    async translateContent(dto) {
        try {
            const response = await this.axios.post(`/properties/translate-content`, dto);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async unarchive(id) {
        try {
            const response = await this.axios.post(`/properties/${id}/unarchive`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async delete(id) {
        try {
            const response = await this.axios.delete(`/properties/${id}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async generateDocument(id, type, language) {
        try {
            const response = await this.axios.post(`/properties/${id}/generate-document/${type}?language=${encodeURIComponent(language)}`, undefined, {
                responseType: "blob",
            });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async getPropertyVisits(propertyId) {
        try {
            const response = await this.axios.get(`/properties/${propertyId}/visits`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async getVisit(visitId) {
        try {
            const response = await this.axios.get(`/property-visits/${visitId}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async getNextPropertyVisitForContact(propertyId, contactId) {
        try {
            const response = await this.axios.get(`/properties/${propertyId}/get-next-visit-for-contact/${contactId}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async countNextVisits() {
        try {
            const response = await this.axios.get(`/property-visits/next-visits/count`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async createVisit(dto) {
        try {
            const response = await this.axios.post(`/property-visits`, dto);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async editVisitDate(visitId, newVisitDate, newDurationMinutes) {
        try {
            const response = await this.axios.patch(`/property-visits/${visitId}/set-visit-date`, { newVisitDate, newDurationMinutes });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async createVisitReportDocument(visitId) {
        try {
            const response = await this.axios.post(`/property-visits/${visitId}/create-visit-report-document`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async editVisit(visitId, dto) {
        try {
            const response = await this.axios.patch(`/property-visits/${visitId}`, dto);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async deleteVisit(visitId) {
        try {
            const response = await this.axios.delete(`/property-visits/${visitId}`);
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    async getPropertiesWithoutProject(filter) {
        try {
            const response = await this.axios.get("/properties/without-project", {
                params: filter,
            });
            return response.data;
        }
        catch (e) {
            console.log("error", e);
            throw e;
        }
    }
}
